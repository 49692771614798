import React, { useEffect, useState } from "react";

import FrequentContacts from "../../components/FrequentContacts/FrequentContacts";

import { ReactComponent as Boat } from "../../assets/images/boat.svg";

import "./Profile.scss";
import { getProfile } from "../../utils/JWTDecoder";
const Profile = () => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const profile = getProfile();
    setProfile(profile);
  }, []);

  return (
    <div className="profile">
      <div className="profile__container">
        <section>
          <h2>Profile</h2>
          <div className="profile__container-box">
            <img src={profile?.photo} alt="profile" />
            <div>
              <span>{profile?.name}</span>
              <p>{profile?.email}</p>
            </div>
          </div>
          <div className="profile__upgrade">
            <Boat />
            <div className="profile__upgrade-title">Upgrade to Premium</div>
            <p>
              AwayMail lets you control whch contacts or keywords you want to
              allow through while you take a break from email.
            </p>
            <button>Upgrade to full version</button>
          </div>
        </section>
      </div>
      <FrequentContacts />{" "}
    </div>
  );
};

export default Profile;
