import React from "react";

import { ReactComponent as Dropdown } from "../../assets/icons/dropdown.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as Swimmer } from "../../assets/images/swimmer.svg";

import Feedbox from "../../components/Feedbox/Feedbox";
import FrequentContacts from "../../components/FrequentContacts/FrequentContacts";

import "./Feed.scss";

const Feed = ({skiTrip, setQuietTime, quietTime, setSkiTrip}) => {
  

  return (
    <div className="feed__box">
      <div className={`feed__box-menu ${(quietTime || skiTrip) && "away"}`}>
        {!(quietTime || skiTrip) ? (
          <div className="checkbox">
            <input type="checkbox" />
            <Dropdown />
          </div>
        ) : (
          <div className="away">
            Active aways:
            {skiTrip && (
              <main>
                <span></span>Ski Trip
              </main>
            )}
            {quietTime && (
              <main>
                <span></span>Quiet Time
              </main>
            )}
          </div>
        )}
        <section>
          <div>278 Messages &nbsp; • &nbsp; 4 Unread</div>
        </section>
      </div>
      <div className="feed__list-container-box">
        <div className="feed__list-container-overlay">
          {(skiTrip || quietTime) && (
            <div className="away__box">
              <Swimmer />
              <h2>Inbox paused</h2>
              <p>
                You currently have an active Away. Want to resume receiving
                messages?
              </p>
              <div>Manage Active Aways</div>
            </div>
          )}
          <div
            className={`feed__list-container ${
              skiTrip || quietTime ? "away" : ""
            }`}
          >
            <div className="feed__list-day">
              <Clock />
              Today
            </div>
            <main>
              <Feedbox
                image={true}
                name={"Brie Gonzalez"}
                body={
                  "Hi Amelia, I hope everything is going smoothly and curious if we want to meet to prep for... "
                }
                subject={"Details about the new project"}
                read={true}
                email={"brie.gonzalez@someemail.com"}
              />
              <Feedbox
                image={true}
                name={"Brie Gonzalez"}
                body={
                  "Hi Amelia, I hope everything is going smoothly and curious if we want to meet to prep for... "
                }
                subject={"Details about the new project"}
                read={true}
                email={"brie.gonzalez@someemail.com"}
              />
              <Feedbox
                image={true}
                name={"Brie Gonzalez"}
                body={
                  "Hi Amelia, I hope everything is going smoothly and curious if we want to meet to prep for... "
                }
                subject={"Details about the new project"}
                read={true}
                email={"brie.gonzalez@someemail.com"}
              />
            </main>
            <div className="feed__list-day">
              <Clock />
              Yesterday
            </div>
            <main>
              <Feedbox
                image={false}
                name={"Brie Gonzalez"}
                body={
                  "Hi Amelia, I hope everything is going smoothly and curious if we want to meet to prep for... "
                }
                subject={"Details about the new project"}
                read={false}
                email={"brie.gonzalez@someemail.com"}
              />
            </main>
          </div>
        </div>
        <FrequentContacts
          skiTrip={skiTrip}
          setSkiTrip={setSkiTrip}
          setQuietTime={setQuietTime}
          quietTime={quietTime}
        />
      </div>
    </div>
  );
};

export default Feed;
