import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedInbox: "Classic",
};

export const SidebarSlice = createSlice({
  name: "Sidebar",
  initialState,
  reducers: {
    changeSelectedInbox: (state) => {
      state.selectedInbox =
        state.selectedInbox === "Classic" ? "Feed" : "Classic";
    },
  },

  extraReducers: {},
});

export const { changeSelectedInbox } = SidebarSlice.actions;

export default SidebarSlice.reducer;
