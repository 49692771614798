import React from "react";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import "./EmailList.scss";

const EmailList = ({ name, email, subject, body, time, read, id }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={(e) => {
        if (e.target.tagName !== "INPUT") {
          navigate(`/mail/${id}`);
        }
      }}
      className="emaillist"
    >
      <div className="emaillist-row-1">
        <input type="checkbox" />
        <div className="avatar">{name=== "To: " ? email.slice(0, 1) :(name.slice(0, 1) === "'" || name.slice(0,1) === '"' ? name.slice(1,2) :  name.slice(0,1)) }</div>
        <p className={`${read ? "read" : ""}`}>{`${name} ${email}`}</p>
        <div className={`${read ? "read" : ""}`}>{time}</div>
      </div>
      <div className="emaillist-row-2">
        <div>
          <span>{subject}</span> -{" "}
          {body?.length < 205 ? parse(body) : parse(body.slice(0, 200)) + "..."}
        </div>
        {read && <main></main>}
      </div>
    </div>
  );
};

export default EmailList;
