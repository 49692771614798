import { configureStore } from "@reduxjs/toolkit";
import InboxReducer from "./Inbox/Inbox";
import SidebarReducer from "./Sidebar/Sidebar";
import AwaysReducer from "./Aways/Aways";
import AuthenticationReducer from "./Authentication/Authentication";

export const store = configureStore({
  reducer: {
    inbox: InboxReducer,
    sidebar: SidebarReducer,
    aways: AwaysReducer,
    authentication: AuthenticationReducer,
  },
});
