import React, { useEffect, useState } from "react";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Duration } from "../../assets/icons/duration.svg";
import { ReactComponent as Contact } from "../../assets/icons/contact.svg";

import moment from "moment";
import { DatePicker } from "antd";
import { getProfile } from "../../utils/JWTDecoder";

import "./CreateAway.scss";
import { useDispatch } from "react-redux";
import { createAway, getAwaysList } from "../../redux/Aways/Aways";
import { useNavigate } from "react-router-dom";
const CreateAway = ({ setAway }) => {
  const [selected, setSelected] = useState(1);
  const [email, setEmail] = useState("");

  //   Away Data
  const [title, setTitle] = useState("Away title 1");
  const [duration, setDuration] = useState("Until tomorrow, 9AM");
  const [message, setMessage] = useState(
    "Thanks for your message. I am currently away from checking email. I will be back to check email on <date>. If you need to reach me, please click here. "
  );
  // const [contacts, setContacts] = useState("");
  const [keywords, setKeywords] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSelect = (num) => {
    setSelected(num);
  };

  const handleDuration = (dur) => {
    let date;

    if (dur === "Until tomorrow, 9AM") {
      date = moment({ hour: 9 }).add(1, "days")._d;
    } else if (dur === "Until next week") {
      date = moment().add(1, "weeks")._d;
    }

    setDuration(date ? date : dur);
    handleSelect(2);
  };

  const handleSubmit = () => {
    const data = {
      title,
      message,
      deactivateDate: new Date(duration).toISOString(),
      keywords: keywords.split(","),
    };
    dispatch(createAway(data)).then((data) => {
      dispatch(getAwaysList());
      setAway(false);
      navigate(0);
    });
  };

  useEffect(() => {
    const profile = getProfile();
    setEmail(profile.email);
  }, []);

  return (
    <div className="createAway">
      <Close onClick={() => setAway(false)} className="createAway__close" />
      <main>
        <h2>Create Away</h2>
        <p>Create an Away Rule for {email}</p>
        <section>
          <div
            onClick={(event) => {
              if (event.target === event.currentTarget) handleSelect(1);
            }}
            className={`box ${selected === 1 ? "duration" : ""}`}
          >
            {selected !== 1 && "Duration"}
            {selected !== 1 ? (
              <span>
                {typeof duration === "object"
                  ? moment(duration).format("lll")
                  : duration}
              </span>
            ) : (
              <>
                <div className="box__title">Duration</div>
                <ul>
                  <li onClick={() => handleDuration("Until tomorrow, 9AM")}>
                    <Duration />
                    <span>Until tomorrow, 9AM</span>
                  </li>
                  <li onClick={() => handleDuration("Until next week")}>
                    <Duration /> <span>Until next week</span>
                  </li>
                  <li>
                    <Duration />
                    <DatePicker
                      showTime
                      placeholder="Add custom time"
                      disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return (
                          current && current < moment(customDate, "YYYY-MM-DD")
                        );
                      }}
                      style={{
                        width: "100%",
                        flex: 1,
                        border: "none",
                      }}
                      onChange={(e) => handleDuration(e.$d)}
                    />
                  </li>
                </ul>
              </>
            )}
          </div>
          <div
            onClick={(event) => {
              if (event.target === event.currentTarget) handleSelect(2);
            }}
            className={`box ${selected === 2 ? "title" : ""}`}
          >
            {selected !== 2 && "Away Title"}
            {selected !== 2 ? (
              <span>{title}</span>
            ) : (
              <>
                <div className="box__title">Name your Away</div>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter away title"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      setSelected(3);
                    }
                  }}
                />
              </>
            )}
          </div>
          <div
            onClick={(event) => {
              if (event.target === event.currentTarget) handleSelect(3);
            }}
            className={`box ${selected === 3 ? "message" : ""}`}
          >
            {selected !== 3 && "Away Message"}
            {selected !== 3 ? (
              <span>
                {message.length > 20 ? `${message.slice(0, 20)}...` : message}
              </span>
            ) : (
              <>
                <div className="box__title">Away Message</div>
                <textarea
                  placeholder="Enter away message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </>
            )}
          </div>
          <div
            onClick={(event) => {
              if (event.target === event.currentTarget) handleSelect(4);
            }}
            className={`box ${selected === 4 ? "contact" : ""}`}
          >
            {selected !== 4 && "Allow Contacts"}
            {selected !== 4 ? (
              <span>None</span>
            ) : (
              <>
                <div className="box__title">Allow Contacts</div>
                <div className="contact__box">
                  <Contact /> Add Contact
                </div>
              </>
            )}
          </div>
          <div
            onClick={(event) => {
              if (event.target === event.currentTarget) handleSelect(5);
            }}
            className={`box ${selected === 5 ? "keywords" : ""}`}
          >
            {selected !== 5 && "Allow Keywords"}
            {selected !== 5 ? (
              <span>
                {" "}
                {keywords.length > 20
                  ? `${keywords.slice(0, 20)}...`
                  : keywords}
              </span>
            ) : (
              <>
                <div className="box__title">Allow Keywords</div>
                <textarea
                  placeholder="Enter keywords"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                />
                <p>Use commas to separate each keyword. </p>
              </>
            )}
          </div>
        </section>
      </main>
      <footer>
        <div className="progress">
          <div className={`${selected === 1 && "active"}`}></div>
          <div className={`${selected === 2 && "active"}`}></div>
          <div className={`${selected === 3 && "active"}`}></div>
          <div className={`${selected === 4 && "active"}`}></div>
          <div className={`${selected === 5 && "active"}`}></div>
        </div>
        <button
          onClick={() => handleSubmit()}
          disabled={!(title && message && duration)}
        >
          Set Away
        </button>
      </footer>
    </div>
  );
};

export default CreateAway;
