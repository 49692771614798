import React, { useState } from "react";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as NoSearches } from "../../assets/images/no-searches.svg";
import { ReactComponent as Recent } from "../../assets/icons/recent.svg";

import "./Searchbar.scss";

const Searchbar = () => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  const recentSearches = [
    "projects",
    "auto repair",
    "apple watch order",
    "script",
    "hotel confirmation",
    "car rental",
  ];

  return (
    <div className={`searchbar ${open ? "active" : ""}`}>
      <Search />
      <input
        type="text"
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
      />
      {open && (
        <div className="history">
          {recentSearches.length > 10 ? (
            recentSearches.map((s, ind) => (
              <div key={ind}>
                <Recent />
                {s}
              </div>
            ))
          ) : (
            <NoSearches />
          )}
        </div>
      )}
    </div>
  );
};

export default Searchbar;
