import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./containers/Login/Login";

import Layout from "./Routes/Layout";

const App = () => {
  const token = localStorage.getItem("token");

  return (
    <div className="App">
      <Routes>
        {/* Onboarding */}
        {token ? (
          <>
            <Route path="/login" element={<Navigate to={"/"} />} />
            {/* Inbox */}
            <Route path="/" element={<Layout path={"/"} />} />
            <Route path="/feed" element={<Layout path={"/feed"} />} />

            {/* Email */}
            <Route path="/mail/:id" element={<Layout path={"/mail/:id"} />} />

            {/* Aways */}
            <Route
              path="/manage-aways"
              element={<Layout path={"/manage-aways"} />}
            />

            {/* Profile */}
            <Route path="/profile" element={<Layout path={"/profile"} />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to={"/login"} />} />
          </>
        )}
      </Routes>
    </div>
  );
};

export default App;
