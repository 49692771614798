import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { SnackbarProvider } from "notistack";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <SnackbarProvider maxSnack={3} style={{ zIndex: 12000 }}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#49b2b9",
          },
        }}
      >
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </ConfigProvider>
    </SnackbarProvider>
  </Provider>
  // </React.StrictMode>
);
