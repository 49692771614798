import React from "react";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Google } from "../../assets/icons/google.svg";
import Swimmer from "../../assets/images/swimmer.svg";
import Plane from "../../assets/images/plane.svg";
import "./Login.scss";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { handleLogin } from "../../redux/Authentication/Authentication";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/gmail.compose " +
      "https://www.googleapis.com/auth/gmail.labels " +
      "https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send",
    onSuccess: async (codeResponse) => {
      dispatch(handleLogin(codeResponse.code)).then(() => navigate(0));
    },
    onError: async (errorResponse) => {},
  });

  return (
    <div className="login">
      <img className="swimmer" src={Swimmer} alt="swimmer" />
      <img className="plane" src={Plane} alt="plane" />
      <div className="login__container">
        <Logo />
        <p>
          Take total control of your inbox so you can focus on what's important
        </p>
        <div onClick={googleLogin} className="login__container-google">
          <Google />
          Continue with Google
        </div>
      </div>
    </div>
  );
};

export default Login;
