import React from "react";

import img from "../../assets/images/feed-image.png";
import parse from "html-react-parser";

import "./Feedbox.scss";
import { useNavigate } from "react-router-dom";

const Feedbox = ({ name, read, body, subject, image, time, id, email }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={(e) => {
        if (e.target.tagName !== "INPUT") {
          navigate(`/mail/${id}`);
        }
      }}
      className="feedbox"
    >
      <section>
        <div className="feedbox__row-1">
          <input type="checkbox" />
          {read && <span></span>}
          <div className="avatar">
            {name === "To: "
              ? email.slice(0, 1)
              : name.slice(0, 1) === "'" || name.slice(0, 1) === '"'
              ? name.slice(1, 2)
              : name.slice(0, 1)}
          </div>
          <div className="name">{`${name} ${
            name === "To: " ? email : ""
          } • ${time}`}</div>
        </div>
        <div className="feedbox__row-2">
          <div className="title">{subject}</div>
          <p>
            {body?.length < 205
              ? parse(body)
              : parse(body.slice(0, 200) + "...")}
          </p>
        </div>
      </section>
      {image && <img src={img} alt="" />}
    </div>
  );
};

export default Feedbox;
