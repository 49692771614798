import React, { useEffect, useState } from "react";

import Inbox from "../containers/Inbox/Inbox";
import Feed from "../containers/Feed/Feed";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";

import { ReactComponent as Pen } from "../assets/icons/pen.svg";
import Profile from "../containers/Profile/Profile";
import EmailDetail from "../containers/EmailDetail/EmailDetail";
import ComposeEmail from "../components/ComposeEmail/ComposeEmail";
import ManageAways from "../containers/ManageAways/ManageAways";
import CreateAway from "../components/CreateAway/CreateAway";
import { useDispatch } from "react-redux";
import { getAwaysList } from "../redux/Aways/Aways";

const Layout = ({ path }) => {
  const [compose, setCompose] = useState(false);

  const [skiTrip, setSkiTrip] = useState(true);
  const [quietTime, setQuietTime] = useState(true);

  const [away, setAway] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAwaysList());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {away && <CreateAway setAway={setAway} />}
      <div className="main">
        {compose && (
          <ComposeEmail
            away={Boolean(skiTrip || quietTime)}
            setCompose={setCompose}
          />
        )}
        {path !== "/profile" && (
          <div onClick={() => setCompose(true)} className="write__email">
            <Pen />
          </div>
        )}
        <Navbar />
        <div className="container">
          <Sidebar away={away} setAway={setAway} />
          {path === "/" && <Inbox />}
          {path === "/feed" && (
            <Feed
              skiTrip={skiTrip}
              setSkiTrip={setSkiTrip}
              setQuietTime={setQuietTime}
              quietTime={quietTime}
            />
          )}
          {path === "/profile" && <Profile />}
          {path === "/mail/:id" && <EmailDetail />}
          {path === "/manage-aways" && (
            <ManageAways away={away} setAway={setAway} />
          )}
        </div>
      </div>
    </>
  );
};

export default Layout;
