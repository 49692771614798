import React, { useEffect, useState } from "react";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash-email.svg";
import { ReactComponent as Attachment } from "../../assets/icons/attachment.svg";
import { ReactComponent as Send } from "../../assets/icons/send.svg";

import { getProfile } from "../../utils/JWTDecoder";

import "./ComposeEmail.scss";
import { useDispatch } from "react-redux";
import { getFileUrl, sendMail } from "../../redux/Inbox/Inbox";
import { useSnackbar } from "notistack";
import { useFilePicker } from "use-file-picker";
import prettyBytes from "pretty-bytes";

const ComposeEmail = ({ setCompose, away }) => {
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const [openFileSelector, { filesContent, plainFiles }] = useFilePicker({});

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const profile = getProfile();
    setFrom(profile.email);
  }, []);

  const handleCompose = async () => {
    const attachments_url = [];

    if (plainFiles.length > 0) {
      await Promise.all(
        plainFiles.map((file) => {
          let data = new FormData();
          data.append("file", file);
          return dispatch(getFileUrl(data)).then(({ payload }) =>
            attachments_url.push(payload)
          );
        })
      );
    }

    dispatch(
      sendMail({
        to,
        message: content,
        subject,
        attachments_url,
      })
    ).then((data) => {
      if (data.payload.status === 200) {
        enqueueSnackbar("Email sent successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setCompose(false);
      }
    });
  };

  return (
    <div
      className="composeEmail__container"
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          setCompose(false);
        }
      }}
    >
      <div className="composeEmail">
        {/* {away && (
          <div className="away">
            Receive emails from recipient while Away?
            <Switch
              value={receiveEmail}
              onChange={(e) => setReceiveEmail(e.target.checked)}
            />
          </div>
        )} */}
        <div className="composeEmail__box">
          <Close
            onClick={() => setCompose(false)}
            title="Close"
            className="close"
          />
          <header>New Message</header>
          <section>
            <label>
              <span>To</span>
              <input
                type="email"
                placeholder="Enter email"
                value={to}
                onChange={(e) => setTo(e.target.value)}
              />
            </label>
            <label>
              <span>From</span>
              <input
                disabled={true}
                style={{ color: "#000" }}
                type="email"
                placeholder="Enter email"
                value={from}
              />
            </label>
            <label>
              <input
                type="text"
                placeholder="Enter subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="content"
              placeholder="Write message..."
            ></textarea>
          </section>
          <div className="composeEmail__attachments">
            {filesContent.map((file, index) => {
              return (
                <div className="attachment">
                  <h2>{file.name}</h2>
                  <div>{prettyBytes(plainFiles[index].size)}</div>
                </div>
              );
            })}
          </div>
          <footer>
            <Trash
              onClick={() => {
                setSubject("");
                setContent("");
                setTo("");
              }}
              title="Delete"
            />
            <div>
              <Attachment onClick={openFileSelector} />
              <Send onClick={handleCompose} title="Send message" />
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ComposeEmail;
