import moment from "moment";

export const getName = (value) => {
  return value.slice(0, value.search("<") - 1);
};

export const getFromEmail = (value) => {
  return value.slice(value.search("<"));
};

export const getEmailTime = (value) => {
  return moment(value).format("LT");
};

export const removeSpaces = (str) => {
  str = str.toString();
  return str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ");
};

export const headerDate = (value) => {
  return moment(value).format("LL");
};
