import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const handleLogin = createAsyncThunk("handleLogin", async (code) => {
  try {
    const res = await axios.post(`https://oauth2.googleapis.com/token`, {
      code,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: window.location.origin,
      grant_type: "authorization_code",
    });
    const login = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v3/session`,
      {
        provider: "gmail",
        swift_token: process.env.REACT_APP_SWIFT_TOKEN,
        access_token: res.data.access_token,
        refresh_token: res.data.refresh_token,
        dev_token_key: false,
      },
      {
        headers: {
          "X-agent": "website",
          "X-Client": "gmail",
          "Content-Type": "application/json",
        },
      }
    );

    localStorage.setItem("token", login.data.data.token);

    return res.data;
  } catch (err) {}
});

const initialState = {};
export const AuthenticationSlice = createSlice({
  name: "Authetication",
  initialState,
  reducers: {},

  extraReducers: {
    [handleLogin.pending]: (state) => {},
    [handleLogin.fulfilled]: (state, action) => {},
    [handleLogin.rejected]: (state, { payload }) => {},
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
